
import { useEffect, useState } from "react";
import FeaturePage from "./FeaturePage";
import SampleProfile from "./SampleProfile";
import TopicsSections from "./TopicsSection";
import ComingSoon from "./ComingSoon";

import { Link } from 'react-router-dom'

function LandingPage() {
  let [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    // Add the window on scroll event
    
    setOffsetY(0)

    window.onscroll = (_) => {
      setOffsetY(window.scrollY);
    };
  }, []);

  let locationIcons = (
    <div>
      <div className="location-icon left-[10vw] top-[10vh]" />
      <div className="location-icon left-[15vw] top-[30vh]" />
      <div className="location-icon right-[10vw] top-[30vh]" />
      <div className="location-icon right-[35vw] top-[50vh]" />
      <div className="location-icon right-[20vw] bottom-[10vh]" />
    </div>
  );
  

  return (
    <section className="overflow-hidden">
      <img
        src="topography.svg"
        alt=""
        className="w-full h-[100vh] absolute top-0 object-cover z-0"
        style={{ opacity: 1 - offsetY / 100 }}
      />
      {locationIcons}

      <div className="relative left-1/2 -translate-x-1/2 mt-[33vh] text-center max-w-lg z-10">
        <h1 className="text-slate-100 font-extrabold text-6xl">
          Discover{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-fuchsia-600">
            Miami
          </span>
        </h1>
        <div className="text-slate-100 mt-2">
          Technology and more in South Florida{" "}
          <span className="text-slate-100 bg-slate-600 p-1 rounded-md text-xs font-semibold">
            Coming Soon
          </span>
        </div>

        <div className="mt-[100px]">
          <Link to={'sign-up'} className="inline-block bg-blue-500 text-blue-900 px-5 py-1 rounded-3xl text-lg border-2 border-blue-600 font-semibold animate-pulse">
            Interested?
          </Link>
          <div className="text-slate-50 font-light text-[12px] mt-2">
            Sign up and get early access
          </div>
        </div>
      </div>

      {/* Showcase our features here */}
      <FeaturePage/>
      
      <TopicsSections/>

      <SampleProfile/>

      <ComingSoon/>

    </section>
  );
}

export default LandingPage;
