import React from "react";
import { Link } from 'react-router-dom'

function NavigationBar() {
  function onShareButtonClick() {
    // If the share API is available, just use that and have the OS handle it
    if (navigator.canShare()) {
      navigator.share({
        title: "MiamiTech.io",
        text: "A technology-centered blog about Miami and the rest of South Florida",
        url: "https://www.miamitech.io",
      });
      return;
    }
  }

  return (
    <nav
      className="w-full h-12 bg-slate-800 flex flex-row place-content-between fixed top-0 z-50 items-center"
    >
      <Link to="/">
        <h2 className="inline-block text-center px-2 text-md font-semibold">
          <span className="text-zinc-50">Miami</span>
          <span className="text-blue-400">Tech</span>
        </h2>
      </Link>

      <div className="flex align-middle mr-2">
        <a
          href="/sign-up"
          className="inline-block px-2 h-8 rounded-2xl bg-blue-400 text-blue-900 text-center py-[6px] text-sm font-semibold mr-2"
        >
          Early Sign Up
        </a>
        <button
          className="f bg-blue-400 text-blue-900 p-1 w-8 h-8 rounded-2xl"
          onClick={onShareButtonClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
}

export default NavigationBar;
