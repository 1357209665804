import { useState } from "react";
import { MT } from "../api/MT";
import Picker from "./Picker";
import TopicChooser from "./TopicChooser";
import { useNavigate } from 'react-router-dom'

function SignUp() {
  const [email, setEmail] = useState(getEmailFromParams() ?? "");
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [chosenTopics, setChosenTopics] = useState<string[]>([]);

  let [isLoading, setIsLoading] = useState(false);

  let [lastError, setLastError] = useState('')

  let navigate = useNavigate()

  function getEmailFromParams() {
    let params = new URLSearchParams(window.location.search);
    return params.get("email");
  }

  const pickerItems = [
    "Professional",
    "Student",
    "Business Owner",
    "Hobbyist",
    "None of the above",
  ];

  const allTopics = [
    "Software Engineering",
    "Based in Miami",
    "Crypto",
    "FinTech",
    "Web3",
    "Cloud Computing",
    "Artifical Intelligence",
  ];

  async function handlerSubmit() {

    // Loading state
    setIsLoading(true);
    setLastError('')

    try {
      await MT.signUp.registerForEarlyRelease({
        email: email,
        description: description!,
        tags: chosenTopics,
      });

      navigate('thank-you')

    } catch (error) {
      setLastError((error as Error).message ?? "An error occured")
    }

    // Finished request
    setIsLoading(false);
  }

  return (
    <div>
      <div className="w-full relative left-1/2 -translate-x-1/2 max-w-md p-2 mt-[120px] z-10 mb-6">
        <div className="text-left text-slate-50">
          <h2 className="font-bold text-4xl">Welcome!</h2>
          <div>Let's get to know more about you.</div>
        </div>

        {/* The form to collect data */}
        <form
          className="w-full bg-slate-50 rounded-lg mt-3 p-3"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="font-semibold text-md ml-1">Email</div>
          <input
            placeholder="jane.doe@server.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-9 rounded-lg border p-2"
          />

          <div className="font-semibold text-md ml-1 mt-7">
            What best describes you?
          </div>
          <Picker items={pickerItems} onPicked={setDescription} />

          <div className="font-semibold text-md ml-1 mt-7">
            What interests you?
          </div>
          <div className="font-light text-xs ml-1">
            Choose from topics to help populate your feed upon launch
          </div>
          <TopicChooser topics={allTopics} onChange={setChosenTopics} />

          <button
            className="w-[220px] h-[44px] relative left-1/2 -translate-x-1/2 bg-slate-900 rounded-[22px] mt-[60px] text-white font-semibold transition-opacity"
            onClick={(_) => handlerSubmit()} 
            disabled={isLoading}
            style={{opacity: isLoading ? 0.3 : 1.0}}
          >
            Submit
          </button>
          <div className="mt-2 text-red-500 font-normal text-center">{lastError}</div>
        </form>
      </div>
      <img
        src="background-signup.svg"
        alt="background"
        className="fixed w-full bottom-0 z-0"
      />
    </div>
  );
}

export default SignUp;
