function FeaturePage() {
  return (
    <div className="mt-[240px] relative left-1/2 -translate-x-1/2 w-full max-w-5xl flex flex-col md:flex-row justify-around items-center p-4">
      <IndividualFeature
        title="News and Events"
        icon={"features/feature_news.svg"}
      >
        <div className="text-slate-100 text-md">
          Learn more about popular topics, companies and people transforming the
          Miami area into the next tech hub.
        </div>
      </IndividualFeature>
      <IndividualFeature title="Create" icon={"features/feature_blog.svg"}>
        <div className="text-slate-100 text-md">
          {/* Read articles from creators in different industries or become a
          creator and begin writing your own content to grow your following and
          even earn. */}
          Read articles from creators in different industries. Become a creator to begin writing you own content, grow your following, and earn. 
        </div>
      </IndividualFeature>
      <IndividualFeature title="Careers" icon={"features/feature_jobs.svg"}>
        <div className="text-slate-100 text-md">
          See job postings by different companies and people, seek in-office or
          remote opportunities in the field of software and technology.
        </div>
      </IndividualFeature>
    </div>
  );
}

function IndividualFeature(props: {
  title: string;
  icon: string;
  children: JSX.Element;
}) {
  return (
    <div className="rounded bg-slate-800 w-full max-w-lg m-2 mt-9 pb-3 h-[250px]">
      <div className="relative bg-gradient-to-r from-blue-500 to-indigo-500 p-2 w-[60px] h-[60px] rounded-[30px] left-1/2 -translate-x-1/2 top-[-30px]">
        <img src={props.icon} alt={props.icon} className="object-cover m-1" />
      </div>
      <h3 className="text-center text-lg font-bold w-full text-slate-50">
        {props.title}
      </h3>
      <div className="p-3">{props.children}</div>
    </div>
  );
}

export default FeaturePage;
