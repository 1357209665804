import React from "react";

function TopicsSections() {
  const trending = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 relative top-1"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
      />
    </svg>
  );

  const filter = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
      />
    </svg>
  );

  function createLabels(value: string[]) {
    return (
      <div>
        {value.map((val, i) => (
          <div className="border-b-[1px] text-left w-full text-slate-400 pt-1 pb-1 text-sm pl-2 relative">
            <span className="inline-block mr-2">{i + 1}</span>
            {val}
            {i === 0 ? (
              <span className="text-xs bg-green-400 text-white px-1 inline-block rounded-lg ml-1">
                Most Popular
              </span>
            ) : (
              <span />
            )}
            <div className="absolute right-2 top-0 text-lg font-normal text-blue-400 cursor-pointer">
              +
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <h3 className="font-bold text-2xl text-center mt-11 text-slate-50">
        Personalize Your Feed
      </h3>
      <div className="text-md text-slate-200 text-center">
        Explore and choose from different topics.
      </div>

      <div className="relative left-1/2 -translate-x-1/2 max-w-md w-full h-[200px] p-2 mt-2">
        <div className="w-full h-full bg-slate-50 rounded-lg overflow-hidden relative">
          <div className="text-slate-900 font-semibold text-sm pl-2 mt-1 text-left w-full border-b-[1px] pb-2">
            Trending Topics <span className="inline-block">{trending}</span>
            <div className="absolute right-1 top-2">{filter}</div>
          </div>
          {createLabels(["Artificial Intelligence", "Web3", "Miami", "Data"])}
        </div>
      </div>
    </div>
  );
}

export default TopicsSections;
