import EmailValidator from "../../misc/EmailValidator";

interface SignUpInput {
  email: string;
  description: string;
  tags: string[];
}

interface SignUpOutput {
  success: boolean;
  message: string | undefined;
}

class SignUp {
  private endpoint: URL;

  constructor(endpoint: URL) {
    this.endpoint = endpoint;
  }

  async registerForEarlyRelease(input: SignUpInput): Promise<SignUpOutput> {
    if (!EmailValidator.isValid(input.email)) {
      throw new Error("Invalid email address");
    }

    const response = await fetch(this.endpoint + "api/v1/sign-up", {
      method: "POST",
      body: JSON.stringify(input),
      headers: { "Content-Type": "application/json" },
    });

    const json = await response.json();

    if (response.ok) {
      return {
        success: true,
        message: undefined,
      };
    }

    return {
      success: false,
      message: json?.message,
    };
  }
}

export default SignUp;
