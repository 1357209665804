import React, { useState } from "react";

function Picker(props: { items: string[]; onPicked: (item: string) => void }) {
  let [current, setCurrent] = useState<string | undefined>(undefined);

  function didChooseItem(item: string) {
    setCurrent(item);
    props.onPicked(item);
  }

  function classNames(item: string) {
    return `pl-2 
    h-[44px] 
    hover:bg-blue-50 
    cursor-pointer 
    text-sm 
    font-semibold 
    text-slate-800 
    flex flex-row 
    items-center 
    justify-between
    transition-colors
    ${item === current ? "bg-blue-100" : "bg-white"}
    `;
  }

  const checkmark = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-blue-400 mr-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );

  return (
    <div className="rounded-lg bg-white w-full overflow-hidden">
      {props.items.map((item) => (
        <div
          id={item}
          onClick={(_) => didChooseItem(item)}
          className={classNames(item)}
        >
          {item}
          {item === current ? checkmark : <div />}
        </div>
      ))}
    </div>
  );
}

export default Picker;
