function SampleProfile() {
  return (
    <div className="text-center w-full mt-11 mb-2">
      <h3 className="font-bold text-2xl mb-4">
        <span className="text-slate-50">Become </span>
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-indigo-300">
          Verified
        </span>
      </h3>

      <div className="text-slate-50 text-sm">
        Apply and become a{" "}
        <span className="inline-block bg-blue-100 text-blue-800 pl-5 pr-2 rounded-lg font-semibold relative">
            <img src="checkmark.svg" alt="Checkmark" className="w-4 h-4 absolute left-1 top-[2px]"/>
          Verified
        </span>{" "}
        creator
        <br />
        on our network
      </div>

      <div className="w-[200px] h-[300px] bg-slate-50 rounded-lg relative left-1/2 -translate-x-1/2 mt-3">
        <div className="bg-gradient-to-r from-blue-200 to-indigo-200 relative w-full h-[60px] rounded-tl rounded-tr" />
        <img
          src="5845e755fb0b0755fa99d7f3.png"
          alt="Profile"
          className="object-cover w-[60px] h-[60px] rounded-[30px] relative mt-[-30px] left-1/2 -translate-x-1/2 bg-slate-400 p-1"
        />
        <div className="flex flex-row items-center">
          <div className="text-left ml-2 font-semibold text-md">Dave</div>
          <img src="checkmark.svg" alt="checkmark" className="w-4 h-4 ml-1" />
        </div>
        <div className="text-left ml-2 font-normal text-xs">
          I am a software engineer and Dodgecoin enthusiast
        </div>
        <div className="text-[10px] font-semibold bg-slate-300 w-[50px] text-center rounded-lg ml-2 mt-1">
          Miami
        </div>
        <div className="absolute w-[150px] h-[30px] rounded-[15px] bg-black bottom-1 left-1/2 -translate-x-1/2 text-slate-50 font-semibold text-sm pt-1">
          Follow
        </div>
      </div>
    </div>
  );
}

export default SampleProfile;
