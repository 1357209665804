import React, { useState } from "react";
import EmailValidator from '../misc/EmailValidator'

function ComingSoon() {
  let [email, setEmail] = useState("");

  const notification = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 m-[2px]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
      />
    </svg>
  );

  function renderButton() {
    if (EmailValidator.isValid(email)) {
      return (
        <div className="text-center">
          <a
            className="inline-block bg-blue-500 text-blue-900 px-5 py-1 rounded-3xl text-lg border-2 border-blue-600 font-semibold animate-pulse"
            href={`sign-up?email=${email}`}
          >
            Early Sign Up
          </a>
        </div>
      );
    }

    return <div />;
  }

  return (
    <div className="mt-8">
      <div className="relative left-1/2 -translate-x-1/2 max-w-md w-full h-[200px] p-3">
        <h3 className="font-bold text-2xl text-slate-50 text-center">
          Much More Coming
        </h3>
        <div className="text-slate-100 text-xs text-center mx-3">
          Keep up to date with the latest improvements and feature additions as
          we build out the platform.
        </div>

        <div className="p-3 relative">
          <div className="w-[24px] h-[24px] rounded-[12px] absolute right-[-2px] top-[6px] animate-pulse bg-blue-600 text-blue-100">
            {notification}
          </div>
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="somebody@gmail.com"
            inputMode="email"
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            className="w-full h-9 outline-0 p-1 bg-slate-600 rounded-lg text-slate-50 mt-2 px-2 focus:outline-none overflow-hidden"
          />
        </div>

        {renderButton()}
      </div>
    </div>
  );
}

export default ComingSoon;
