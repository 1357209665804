import { useState } from "react";

function TopicChooser(props: {
  topics: string[];
  onChange: (topics: string[]) => void;
}) {
  let [chosenTopics, setChosenTopics] = useState<string[]>([]);

  function renderPill(item: string) {
    let classes = `m-1 px-2 rounded-xl font-semibold text-sm border-blue-300 ${
      chosenTopics.includes(item) ? "text-white" : "text-blue-500"
    } border cursor-pointer ${
      chosenTopics.includes(item) ? "bg-blue-500" : "bg-white"
    }`;

    return (
      <div className={classes} onClick={(_) => handleClick(item)}>
        {item}
      </div>
    );
  }

  function handleClick(item: string) {
    if (chosenTopics.includes(item)) {
      // We need to remove the item
      setChosenTopics(chosenTopics.filter((e) => e !== item));
      return;
    }

    // Add the new item
    setChosenTopics(chosenTopics.concat([item]));
  }

  return (
    <div className="w-full rounded-lg flex flex-row flex-wrap bg-white select-none">
      {props.topics.map(renderPill)}
    </div>
  );
}

export default TopicChooser;
