import { Link } from "react-router-dom";

function ThankYou() {
  const leftArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6 inline"
    >
      <path
        fillRule="evenodd"
        d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <div>
      <div className="text-white text-center relative top-[33vh] z-10">
        <h2 className="font-bold text-3xl">Thank You!</h2>
        <div className="m-2">We'll notify you with any updates and upcoming features</div>

        <Link to={"/"} className="mt-12 inline-block">
        {leftArrow} Take Me Home
        </Link>
      </div>
      <img
        src="../../topography.svg"
        alt="background"
        className="fixed left-0 top-0 w-[100vw] h-[100vh] z-0 object-cover"
      />
    </div>
  );
}

export default ThankYou;
