
import SignUp from "./SignUp/SignUp"


class MT {

    private static config:URL = new URL(process.env.NODE_ENV === 'development' ? 'http:127.0.0.1:8080' : 'https://miami-tech.herokuapp.com')

    static signUp = new SignUp(this.config)
}

export { MT }