import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignUp from "./LandingPage+SignUp/SignUp";
import ThankYou from "./LandingPage+SignUp/ThankYou";
import LandingPage from "./LandingPage/LandingPage";
import Footer from "./Shared/Components/Footer";

import NavigationBar from "./Shared/Components/NavigationBar";

function createPage(content: JSX.Element) {
  return (
    <div>
      <NavigationBar />
      {content}
      <Footer/>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: createPage(<LandingPage />),
  },
  {
    path: "sign-up",
    element: createPage(<SignUp />),
  },
  {
    path: "sign-up/thank-you",
    element: createPage(<ThankYou />),
  }
]);

export default function init(id: string) {
  createRoot(document.getElementById(id)!).render(
    <RouterProvider router={router} />
  );
}
